@import 'react-perfect-scrollbar/dist/css/styles.css';
@import 'bootstrap/dist/css/bootstrap.min.css';

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@font-face {
	font-family: 'ArtWishList';
	src: local('ArtWishList'),
		url(./assets/fonts/untitled-sans-web-regular.woff) format('woff');
	/* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
	font-family: 'ArtWishListMedium';
	src: local('ArtWishListMedium'),
		url(./assets/fonts/untitled-sans-web-medium.woff) format('woff');
	/* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

body {
	margin: 0;
	/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
	font-family: 'ArtWishList', sans-serif !important;
	width: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
	font-size: 16px;
}